import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Modal, Button } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Tinh1sContext } from '@techartvn/tinh1s-sdk';
import { Comments, FacebookProvider } from 'react-facebook';
import AppComponent from '../components/AppComponent';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import context from '../utils/context';
import { isBrowser } from '../utils/browser';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > div:first-child {
    flex: 1;
  }
`;

const CommentContainer = styled.div`
  min-height: 205px;
`;

const EmbeddedCode = styled.pre`
  padding: 10px;
  border-radius: 4px;
  background: #eaeaea;
`;

function AppMount({ data }) {
  const { app } = data;
  const [isEmbeddedModalVisible, setIsEmbeddedModalVisible] = useState(false);
  const [embeddedUrl, setEmbeddedUrl] = useState('');

  useEffect(() => {
    setEmbeddedUrl(`${window.location.origin}/embedded/apps/${app.fullSlug}`);
  }, []);

  const showModal = useCallback(() => {
    setIsEmbeddedModalVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsEmbeddedModalVisible(false);
  }, []);

  return (
    <Tinh1sContext.Provider value={context}>
      <Layout breakCrumbs={[{label: app.name, labelVersion: app.version}]} toolbar={<Button onClick={showModal} shape="circle" icon={<ShareAltOutlined />} />}>
        <SEO title={app.name} description={app.description} keywords={app.keywords} />
        {app.ssr || isBrowser() && (
          <Wrapper>
            <AppComponent slug={app.slug} />
            <br />
            <br />
            <CommentContainer>
              <FacebookProvider appId={process.env.GATSBY_FACEBOOK_APP_ID}>
                <Comments
                  href={`${process.env.GATSBY_APP_URL}/${process.env.GATSBY_APP_SLUG}/${app.slug}`}
                />
              </FacebookProvider>
            </CommentContainer>
          </Wrapper>
        )}
        <Modal title={`Nhúng ${app.name}`} visible={isEmbeddedModalVisible} footer={false} onCancel={handleCancel}>
          <span>Chèn đoạn code sau vào trang HTML của bạn:</span>
          <br/>
          <br/>
          <EmbeddedCode>{`<div style="border: 1px solid #e8e8e8; display: flex;">
  <iframe src="${embeddedUrl}" height="300" width="100%" style="border: none; margin: 0;" />
</div>`}</EmbeddedCode>
        </Modal>
      </Layout>
    </Tinh1sContext.Provider>
  );
}

AppMount.propTypes = {
  data: PropTypes.shape({
    app: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      fullSlug: PropTypes.string,
      version: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
};

export default AppMount;

export const pageQuery = graphql`
  query($rawId: String!) {
    app(rawId: { eq: $rawId }) {
      name
      slug
      fullSlug
      version
      description
      keywords
    }
  }
`;
